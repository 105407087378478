<template>
    <modal ref="modalComprasAfiliado" titulo="Afiliados" tamano="modal-xl" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-12 px-0 d-flex">
                <div class="col-auto px-3" />
                <div class="col">
                    <p class="text-general f-18 f-500">
                        {{ titulo }}
                    </p>
                </div>
                <div class="col-3">
                    <el-input v-model="buscar" prefix-icon="icon-search" placeholder="Buscar afiliado" size="small" class="w-100 br-20" />
                </div>
            </div>
            <div class="col-12 px-0 mt-3">
                <el-table
                :data="afiliados.filter(data => !buscar || data.nombre.toLowerCase().includes(buscar.toLowerCase()))"
                style="width: 100%"
                height="calc(100vh - 413px)"
                header-row-class-name="text-general f-16"
                >
                    <el-table-column
                    prop="nombre"
                    label="Nombre"
                    min-width="150"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="antiguedad"
                    label="Antigüedad"
                    min-width="150"
                    >
                        <template slot-scope="scope">
                            <p>{{ fechaAntiguedad(scope.row.antiguedad) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Cupo"
                    min-width="180"
                    >
                        <template slot-scope="scope">
                            <template v-if="scope.row.id_user === afiliadoEdit">
                                <div class="d-flex">
                                    <money v-model="scope.row.cupo" class="input-money" v-bind="money" />
                                    <el-tooltip content="Actualizar" placement="bottom" effect="light">
                                        <i class="icon-ok-circled-outline f-18 cr-pointer py-1" style="color:#29D884;" @click="putCupoAfiliados(scope.row.id_user, scope.row.id_convenio, scope.row.cupo)" />
                                    </el-tooltip>
                                </div>
                            </template>
                            <template v-else>
                                {{ scope.row.cupo == 0 ? 'Ilimitado' : separadorNumeroDecimales(scope.row.cupo) }}
                                <el-tooltip content="Editar" placement="bottom" effect="light">
                                    <i class="icon-pencil-outline f-18 cr-pointer ml-1 text-general" @click="editarCupoAfiliado(scope.row.id_user)" />
                                </el-tooltip>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="pedidos_periodo"
                    label="Pedidos Periodo"
                    min-width="150"
                    class-name="text-center"
                    />
                    <el-table-column
                    label="Compras del periodo"
                    min-width="200"
                    >
                        <template slot-scope="scope">
                            <p>{{ separadorNumeroDecimales(scope.row.compras_periodo) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Pedidos con convenio"
                    min-width="200"
                    >
                        <template slot-scope="scope">
                            <p>{{ separadorNumeroDecimales(scope.row.pedidos_convenio) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Pedidos de contado"
                    min-width="180"
                    >
                        <template slot-scope="scope">
                            <p>{{ separadorNumeroDecimales(scope.row.pedidos_contado) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Deuda actual"
                    min-width="180"
                    >
                        <template slot-scope="scope">
                            <p>{{ separadorNumeroDecimales(scope.row.deuda_total) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Compras totales"
                    min-width="180"
                    >
                        <template slot-scope="scope">
                            <p>{{ separadorNumeroDecimales(scope.row.compras_totales) }}</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </modal>
</template>
<script>
import Service from '~/services/nomina/nominaDetalleEstadisticas'
import moment from 'moment'
import {Money} from 'v-money'
export default {
    components:{
        Money,
    },
    data(){
        return {
            afiliados: [],
            titulo: "",
            buscar: "",
            afiliadoEdit: null,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },
    methods: {
        toggle(data, fechas){
            let fechaInicial = moment(fechas[0]).format('D/MM/YYYY');
            let fechaFinal = moment(fechas[1]).format('D/MM/YYYY');
            let rango1 = this.separadorNumeroDecimales(data.rango[0]);
            let rango2 = this.separadorNumeroDecimales(data.rango[1]);
            this.titulo = "Afiliados que compraron de "+rango1+" a "+rango2+" del "+fechaInicial+" al "+fechaFinal;
            this.getComprasAfiliados(data.afiliados,fechas);
            this.$refs.modalComprasAfiliado.toggle();
        },
        async getComprasAfiliados(afiliados,fechas){
            try {
                const params = {
                    afiliados: afiliados,
                    fechas: fechas
                };
                const {data} = await Service.getComprasAfiliados(params)
                this.afiliados = data.afiliados;
            } catch(error){
                this.error_catch(error)
            }
        },
        fechaAntiguedad(fecha){
            return moment(fecha).startOf('day').fromNow();
        },
        editarCupoAfiliado(idUser){
            this.afiliadoEdit = idUser;
        },
        async putCupoAfiliados(idUser, idConvenio, cupo){
            try {
                const model = {
                    idConvenio: idConvenio,
                    cupo: cupo
                };
                const {data} = await Service.putCupoAfiliados(idUser, model);
                this.afiliadoEdit = null;
                this.notificacion('Mensaje', data.mensaje, data.tipo);
            } catch(error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-file{
    width: 302px;
    height: 50px;
    background-color: #F6F9FB;
    border: 1px dashed #637381;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-file{
    width: 312px;
    height: 60px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px #00000014;
}

.btn-delete-file{
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: #FF5158;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-download-file{
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: #29D884;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
